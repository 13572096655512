.vpn-page {
  margin-bottom: 50px;

  .page__inner {
    max-width: 1096px !important;
  }

  .details {
    margin-top: 60px;

    .title-details {
      font-size: 36px;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  .title {
    margin-bottom: 20px;
  }

  .vpn-list__item-name__recommend {
    margin-left: 14px;
    vertical-align: top;
    margin-top: 4px;
  }

  .description {
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;

    // .slider-vpn__wrapper  {
    //   max-height: 435px;
    .swiper {
      height: 435px;
    }

    // }
    &__text {
      margin-bottom: 30px;
    }

    &__logo {
      width: 150px;
      height: 150px;
      margin-bottom: 20px;

      img {
        width: 100%;
        border-radius: 50px;
      }
    }

    &__rating {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 20px;

      .rating {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:last-child {
          margin-right: 0;
        }

        &__data {
          padding: 10px 15px 15px;
          margin-bottom: 5px;
          border-bottom: var(--border) var(--border-color);
        }

        &__value {
          font-weight: 700;
          color: var(--color-45);
          font-size: 33px;
          line-height: 30px;
        }

        &__full {
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: var(--color-100b);
        }

        &__place {
          color: var(--color-100b);
          font-size: 12px;
          line-height: 150%;

        }
      }
    }

    &__footer {
      .button_vpn-link {
        margin-bottom: 15px;
      }

      .vpn-discount {
        text-align: center;

      }

      .block__rks-recommended {
        max-width: 200px;
        position: relative;
        background: linear-gradient(to right, #637AC8, #B95EC8);
        border-radius: 6px;
        padding: 2px;
        margin: 0 auto 60px;
        height: fit-content;

        .rks-recommended {
          background: var(--main-background-color);
          padding: 10px 12px;
          border-radius: 4px;
          max-width: 200px;
          text-align: center;

          .text {
            font-size: 10px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            background: linear-gradient(110.69deg, #637AC8 23.52%, #B95EC8 98.77%), #1D1D1B;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          .rks {
            height: 20px;
            width: 177px;
            margin-bottom: 4px;
          }
        }
      }
    }

    &__images {
      margin-bottom: 50px;
    }
  }

}

@media (min-width:680px) {
  .vpn-page {
    margin-bottom: 100px;

    .page__inner {
      position: relative;

      .swiper {
        height: 570px;

        img {
          max-height: 100%;
        }

      }
    }

    .vpn-list__item-name__recommend {
      margin-top: 5px;
    }

    .description {
      &__images {
        margin-bottom: 0;
      }

      &__text,
      &__rating,
      .block__buy-vpn {
        max-width: 650px;
        width: calc(100% - 285px);
        margin-right: auto;
        padding-right: 15px;
      }

      &__footer {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        margin-bottom: 50px;

        .vpn-discount {
          text-align: left;

          &__code {
            display: block;
          }
        }

        .block__buy-vpn {
          display: flex;
          flex-direction: row;
          align-items: center;
          box-sizing: content-box;

          .button_vpn-link {
            height: min-content;
            // max-width: 285px;
            margin-right: 30px;
            margin-left: 0;
            margin-bottom: 0;
          }
        }



        .block__rks-recommended {
          margin-bottom: 0;
          // width: 286px;
        }
      }

      &__logo {
        position: absolute;
        width: 285px;
        top: 0;
        right: 15px;
      }

      &__rating {
        justify-content: flex-start;
      }

    }

    .details {
      margin-top: 30px;
    }
  }
}

@media (min-width:1024px) {
  .vpn-page {
    margin-bottom: 100px;

    .title {
      margin-bottom: 30px;
    }

    .description {
      font-size: 24px;
      margin-bottom: 60px;

      &__rating {
        margin-bottom: 35px;

        .rating {

          margin-right: 35px;

          &:last-child {
            margin-right: 0;
          }

          &__data {}

          &__value {
            font-size: 40px;
          }

          &__full {
            font-size: 16px;
          }

          &__place {
            font-size: 14px;

          }
        }
      }

      &__footer {


        .block__buy-vpn {


          .button_vpn-link {}
        }


      }
    }
  }
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.App-icon,
.App-icon svg {
  width: 100px;
  height: 100px;
  fill: red;
}