.about-page {
  width: 100%;
  flex-grow: 1;

  &__inner {
    max-width: var(--desctop-width);
    margin: 0 auto;
    padding: 0 15px;
  }

  &__title {
    margin-bottom: 20px;

  }

  &__description {
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 20px;
  }
}

@media (min-width:1024px) {
  .about-page {

    &__inner {
      padding: 0 60px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__description {
      font-size: 24px;
      margin-bottom: 30px;
    }

  }
}