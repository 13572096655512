.alternative-page {
  width: 100%;
  margin-bottom: 50px;

  &__inner {
    max-width: var(--desctop-width);
    margin: 0 auto;
    padding: 0 15px;
  }

  &__title {
    margin-bottom: 20px;

  }

  &__description {
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 30px;
  }

  &__banner {
    margin-bottom: 40px;
  }

  img {
    width: 155px;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

@media (min-width:1024px) {
  .alternative-page {
    margin-bottom: 100px;
    margin-right: 60px;
    margin-left: 60px;
    width: auto;

    &__inner {
      padding: 0;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__description {
      font-size: 24px;
      margin-bottom: 60px;
    }

    &__banner {
      margin-bottom: 85px;

    }

    img {
      width: 360px;
    }
  }
}