// // CSS variables, shared between all themes

:root {
  --main-color: #0E0228;
  --main-background-color: #EDECEE;

  --main-color-hover: #4B09D3;
  --second-color: #3C07A8;
  --second-background-color: #fff;
  --add-background-color: #F8F8F8;

  --button-colored-text: #FFFFFF;
  --button-background-color: #FFFFFF;
  --button-background-color-hover: #E5D9FF;

  --color-30: #925DFE;
  --color-30b: #E9E9E9;
  --color-20: #E6E6E6;
  --color-40: #7634FE;
  --color-40b: #BFBFBF;
  --color-45: #5B0BFE;
  --color-50b: #7F7A87;
  --color-60: #4B09D3;
  --color-50: #550CE9;
  --color-45b: #A6A2AD;
  --color-70b: #383740;
  --color-100b: #141316;
  --desctop-width: 1330px;

  --highlight-color: #D2D3D3;

  --border-color: var(--highlight-color);
  --border: 1px solid;

  --padding-page-inner-desktop: 0 60px;

  --color-positive: #07A821;
  --color-neutral: #C7A805;
  --color-negative: #DB0F0F;
  --color-neutral-bad: #db750f;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  -webkit-appearance: none;

  &-thumb {
    border-radius: 5px;
  }

  &-track {
    background: transparent;
  }

  &-thumb {
    background: rgba(0, 0, 0, 0.1);
  }
}

// TODO: move to a separate files
[data-animation-mode=enabled] {
  --transition-duration-instant: .075s;
  --transition-duration-fast: .15s;
  --transition-duration-medium: .25s;
  --transition-duration-slow: .35s;
}

// TODO: disable @mui buttons animation here too
[data-animation-mode=disabled] {
  --transition-duration-instant: 0;
  --transition-duration-fast: 0;
  --transition-duration-medium: 0;
  --transition-duration-slow: 0;
}