.redaction-top {
  height: fit-content;

  &__title {
    text-align: center;
    margin-bottom: 20px;
  }

  &__list {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin-top: 20px;
  }

  &__item {
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;

    &:last-child {
      padding-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    .icon_triangle {
      margin-right: 15px;
      margin-left: 5px;
    }

    &_title {
      display: inline;

      &:hover {
        cursor: pointer;
        color: var(--main-color-hover);

      }
    }
  }



}


@media (min-width: 1024px) {
  .redaction-top {
    height: fit-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    transition: position 2s ease-in-out .5s;

    &__title {
      margin-bottom: 30px;
    }

    &__list {
      margin-top: 30px;
      font-size: 22px;
    }

    &__item {
      padding: 30px 0;
      border-bottom: var(--border) var(--color-20);

      &:last-child {
        border-bottom: none;
      }
      .icon_triangle {
        margin-left: 0;
      }
    }
  }
}