.news-page {
  flex-grow: 1;
  margin: 0 auto 40px;
  max-width: 875px;

  .page__inner {
    max-width: var(--desctop-width);
    margin: 0 auto;
    padding: 0 15px;
  }

  &__title {
    margin-bottom: 30px;

  }

  &__img {
    margin-bottom: 30px;
    width: 100%;
    height: auto;

    img {
      width: inherit;
      border-radius: 20px;
    }
  }

  &__content {
    max-width: 874px;
    margin: 0 auto;


    .news_page__lead {
      font-size: 18px;
      line-height: 150%;
      margin-bottom: 40px;

    }

    .news-page__short__banner {
      margin-top: 40px;

      .details-item__description {
        max-width: 320px;
        line-height: 150%;

        p {
          margin: 0;
          padding: 0;
          opacity: 0.8;
        }

        ul {
          padding-left: 20px;
          opacity: 0.8;
          margin: 0;
        }
      }

      .details-item {
        margin: 10px 0;

        .border-dashed {
          margin: 20px 0;
        }

        .block__buy-vpn {
          &__price {
            font-weight: 700;
            font-size: 24px;
            line-height: 150%;
            color: var(--color-100b);
            margin-bottom: 5px;
          }

          &__service {
            color: var(--color-50b);
            font-size: 16px;
            line-height: 150%;
            text-align: center;
          }

          .button_vpn-link {
            padding-top: 15px;
            padding-bottom: 15px;
            min-width: 240px;
            margin-bottom: 5px;
          }
        }

        &.background {
          padding: 20px;
        }

        &__header {
          display: flex;
          margin-bottom: 22px;
          flex-direction: row;

          &-status {
            font-size: 15px;
          }

          &-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
            flex-grow: 1;
          }
        }
      }
    }

    .news-page__text {
      font-size: 15px;
      line-height: 150%;
      margin-top: 40px;
      margin-bottom: 40px;

      a {
        border-bottom: 1px solid var(--main-color-hover);
      }

      p {
        padding: 5px 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }

      blockquote {
        padding: 20px;
        background: var(--second-background-color);
        border-radius: 20px;
        margin: 20px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.news-page__article.page__wrapper {
  max-width: 1096px !important;

  .news-list__inner {
    margin-top: 40px;
  }
}

.search__not-found {
  padding: 20px 30px;
  line-height: 150%;

  &__text {
    text-align: center;

    margin-bottom: 30px;

    p {
      &:first-child {
        margin-bottom: 30px;
      }
    }

  }

  &__go-main {
    text-align: center;
    color: var(--main-color-hover);

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
}

@media (min-width:1024px) {
  .search__not-found {
    padding: 60px 100px;
    font-size: 24px;

    &__text {

      text-align: center;

    }
  }

  .news-page {
    margin-bottom: 85px;

    .page__inner {
      padding: 0 60px;
    }

    &__title {
      margin-bottom: 40px;
      font-size: 46px;
    }

    &__img {
      margin-bottom: 40px;
    }

    &__content {
      .news-page__short__banner {
        .details-item__description {
          max-width: 100%;
          font-size: 18px;
        }



        .details-item {
          margin: 30px 0;

          .border-dashed {
            margin: 30px 0;
          }

          .block__buy-vpn {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &__price {
              font-size: 36px;
              line-height: 100%;
            }

            .button_vpn-link {
              margin-right: 0;
              margin-bottom: 5px;
            }
          }

          &.background {
            padding: 30px;
          }

          &__header {
            margin-bottom: 30px;

            &-title {
              font-size: 28px;
            }
          }
        }
      }

      .news_page__lead {
        font-size: 24px;
      }


      .news-page__text {
        font-size: 20px;

        p {
          padding: 10px 0;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }

        blockquote {
          padding: 40px;
          margin: 40px 0;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }


  }

  .news-page__article {
    .news-list__wrapper {
      .news-list__inner {

        border-top: var(--border) var(--color-45b);
        padding-top: 40px;

      }
    }
  }
}

@media (min-width: 1440px) {
  .news-page__article {
    .news-list__wrapper {
      .news-list__inner {
        width: 100%;
      }
    }
  }
}