@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/NotoSans-Medium.ttf');
}

@font-face {
  font-family: 'Manrope-Light';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Manrope/Manrope-Light.ttf');
}


html {
  font-size: 14px;
  overflow: hidden;
}



body {
  margin: 0;
  padding: 0;
  font-family: 'NotoSans', 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: var(--main-color);
  background-color: var(--main-background-color);
  width: 100vw;
  height: 100vh;
  overflow-x: auto;



  #root {
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .wrapper {
      min-height: 100vh;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  a {
    text-decoration: none;
    outline: none;
    color: inherit;

    &:hover {
      color: var(--main-color-hover);
    }
  }

  b,
  strong {
    font-weight: 600;
  }

  p {
    padding: 5px 0;
  }

  @media (min-width: 1024px) {
    p {
      padding: 10px 0;
    }
  }
}

ol,
li {
  line-height: 22px;
}

li {
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  body {
    font-size: 16px;
    line-height: 18px;
  }

  ol,
  li {
    line-height: 24px;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:focus-visible {
  outline: none;
}