.input {
  input {
    border: 1px solid var(--color-40b);
    border-radius: 10px;
    width: 100px;
    padding: 0px 20px;
    color: var(--color-100b);
    background-color: var(--second-background-color);

    box-sizing: border-box;

    &::placeholder {
      color: var(--color-40b);
    }

    &:-ms-input-placeholder {
      color: var(--color-40b);
    }

    &::-ms-input-placeholder {
      color: var(--color-40b);
    }

    ;
  }
}