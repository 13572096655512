.filters__wrapper {
  width: 100%;
  margin-bottom: 40px;

  .filters__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;

    .filter__item {
      display: block;
      border-radius: 20px;
      background: var(--button-background-color);
      color: var(--color-60);
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      border: 1px solid transparent;

      &:hover {
        cursor: pointer;
        background-color: var(--button-background-color-hover);
        transition: all .5s;
      }

      &.active {
        border-color: var(--color-60);
      }

      .searchWrapper {
        border: none;
        padding: 0;
        border-radius: 0;
        min-height: 0;


        input {
          margin: 0;
          padding: 10px 20px;

          &:focus {
            &::placeholder {
              opacity: 0.4;
            }

            &::-ms-input-placeholder {
              opacity: 0.4;
            }
          }

          &:hover {
            cursor: pointer;
          }

          &::placeholder {
            color: var(--color-60);
            opacity: 1;
          }

          &::-ms-input-placeholder {
            color: var(--color-60);
          }
        }
      }

      &.filter-payment_methods {
        .searchWrapper {
          input {
            width: 175px;
          }
        }
      }

      &.filter-plarforms {
        .searchWrapper {
          input {
            width: 178px;
          }
        }
      }

      &.filter-countries {
        .searchWrapper {
          input {
            width: 101px;
          }

        }

        .displayNone {
          // display: block !important;
        }
      }

      &.filter-rf {
        padding: 10px 20px;
        display: flex;
        gap: 10px;

        .filter__item-close {
          height: 24px;
        }
      }

      .optionListContainer {
        margin-top: 13px;
        width: 370px;
        background: none;

        max-width: 90vw;

        .optionContainer {
          padding: 15px 0px;
          gap: 10px;
          background: var(--button-background-color);
          border-radius: 20px;
          box-shadow: 0px 1.26875px 4.625px 0px rgba(1, 16, 53, 0.03), 0px 5.8px 13px 0px rgba(1, 16, 53, 0.05), 0px 14.68125px 34.875px 0px rgba(1, 16, 53, 0.07);

          .notFound {
            padding: 10px 20px;
          }

          li {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            color: var(--main-color);
            position: relative;
            padding: 10px 20px;

            &.highlightOption {
              background-color: transparent;
            }

            &.selected {
              background-color: var(--button-background-color-hover);
            }

            &:hover {
              background-color: var(--button-background-color-hover);
              transition: all .5s;
            }

            .checkbox {
              position: absolute;
              width: 30px;
              height: 30px;
            }

            input[type="checkbox"] {
              appearance: none;
              background-color: var(--button-background-color);
              margin: 0;
              font: inherit;
              margin-top: -2px;
              right: 20px;
              width: 30px;
              height: 30px;
              border: 1px solid var(--color-50b);
              border-radius: 6px;
              transform: translateY(-0.075em);

              &:hover {
                cursor: pointer;
              }
            }

            &.selected {
              input[type="checkbox"]::before {
                content: '';
                position: absolute;
                top: 1px;
                left: 3px;
                width: 25px;
                height: 25px;
                background: var(--color-50b);
                -webkit-clip-path: polygon(40% 76%, 84% 24%, 89% 28%, 41% 87%, 0% 49%, 4% 43%);
                clip-path: polygon(40% 76%, 84% 24%, 89% 28%, 41% 87%, 0% 49%, 4% 43%);
                -webkit-transition: transform .3s;
                transition: transform .3s;
              }
            }
          }


        }

      }
    }

    margin-bottom: 15px;
  }

  .filters_selected__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 15px;
    border-top: 1px solid var(--color-40b);
    gap: 15px;

    .filter__item {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      gap: 10px;
      padding: 10px 20px;
      border-radius: 20px;
      background: var(--button-background-color-hover);
      color: var(--color-60);
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      border: 1px solid transparent;


      .filter__item-close {
        height: 24px;

        &:hover {
          cursor: pointer;
          transition: all .5s;
        }
      }

      &.filter__item-delete {
        background: var(--button-background-color);

        &:hover {
          cursor: pointer;
          transition: all .5s;
          background: var(--button-background-color-hover);
        }
      }

    }
  }



}


@media (min-width: 678px) {
  .filters__wrapper {

    .filters__list {
      flex-direction: row;
    }
  }
}


@media (min-width: 1440px) {}