.vpn-discount {

  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 20px;

  &__code {
    padding: 8px 6px;
    color: var(--color-45);
    border: 1px dashed var(--color-40);
    border-radius: 4px;
    display: inline-block;
    width: min-content;
  }
}

@media (min-width:680px) {
  .vpn-discount {
    margin-bottom: 0;

    &__code {
      margin-top: 6px;
    }
  }
}

@media (min-width:1024px) {
  .vpn-discount {
    font-size: 16px;
  }
}