.search__wrapper {
  display: block;

  .search__inner {
    display: flex;
    transition: all .5s;

    &-open {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }

    .btn_search,
    .btn_close_search {
      width: 24px;
      display: flex;

      svg {
        margin: auto;
        stroke: var(--main-color)
      }

      &:hover {
        cursor: pointer;

        svg {
          stroke: var(--main-color-hover)
        }
      }
    }

  }

  .search__input {
    flex-grow: 1;
    position: relative;
    margin-right: 25px;

    .btn_search {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }

  .input {
    width: 100%;

    input {
      height: 44px;
      width: 100%;
      font-size: 15px;
    }
  }

  &.search-open {
    flex-grow: 1;

  }
}



@media (min-width:1024px) {
  .search__wrapper {
    .search__input {
      margin: 0 30px 0 50px;
    }

    .input {
      input {
        font-size: 20px;
      }
    }
  }


}

@media (min-width:1440px) {}