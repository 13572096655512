.categories {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--color-50);
  margin-bottom: 5px;

  a {
    margin-right: 15px;
  }
}

@media (min-width:1024px) {
  .categories {
    font-size: 18px;
  }
}