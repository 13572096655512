.button {
  color: var(--color-60);
  padding: 20px 35px;
  background-color: var(--button-background-color);
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  display: block;
  position: relative;
  height: fit-content;
  &__icon{
    margin-left: 15px;
  }

  &:hover {
    background-color: var(--button-background-color-hover);
    cursor: pointer;
    transition: all .5s;
  }

  &.colored {
    background-color: var(--color-50);
    color: var(--button-colored-text);
    max-width: 330px;
    margin: 0 auto;
    font-weight: 400;
  }

  &.arrow_right {
    position: relative;
    padding: 20px 80px 20px 35px;

    &::after {
      position: absolute;
      top: calc(50% - 5px);
      right: -10px;
      content: '';
      width: 10px;
      height: 10px;
      border-top: 1px solid #6E18C0;
      border-right: 1px solid #6E18C0;
      margin-right: 60px;
      transform: rotate(45deg);
    }
  }
}

@media (min-width:1024px) {
  .button {
    padding: 25px 45px;
    font-size: 20px;
    line-height: 150%;

    &.arrow_right {
      padding: 25px 80px 25px 45px;
    }
  }

}